import './App.css';

const App = () => {
  return (
    <div className="App">
      <header className="App-header">
        <h1>
          Cofactor<span style={{color: 'cyan'}}>.</span>
        </h1>
      </header>
        <div className="App-contact-section-people">
          <div>
            <h4>Jani Koivisto</h4>
            <div>Co-Founder</div>
            <div>jani.koivisto@cofactor.fi</div>
          </div>
          <div>
            <h4>Joona Laine</h4>
            <div>Co-Founder</div>
            <div>joona.laine@cofactor.fi</div>
          </div>
        </div> 
    </div>
  );
}

export default App;
